.admin-product-card-container {
    margin: 20px;

    .admin-product-card {
        border-radius: 20px;
        box-shadow: 2px 2px 8px gray;
        height: 370px;
        min-width: 280px;
        text-transform: capitalize;

        .admin-product-card-image {
            width: 100%;
            max-height: 150px;
            padding: 5px;
            align-self: center;
        }
    }

    span {
        color: gray;
        font-size: 0.8rem;
    }
}

.product-inventory-history-container {
    display: flex;
    font-size: 0.8rem;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 250px;
    overflow-y: scroll;
}

.product-inventory-add-entries-container {
    display: flex;
    flex-direction: column;
    border-top: 2px solid gray;
    padding-top: 30px;

    .product-inventory-add-entries-inputs {
        display: flex;

        .product-inventory-input-quantity {
            width: 70px;
            margin: 10px ;
        }

        .product-inventory-input {
            margin: 10px ;
            width: 150px;
        }
    }

    .product-inventory-input-justification {
        margin: 10px;
        width: 350px;
    }
}

/* Media Query: Large Smartphone */

@media screen and (max-width: 600px) {
    .product-inventory-history-container {
        display: flex;
        font-size: 0.8rem;
        padding-top: 30px;
        padding-bottom: 30px;
        overflow-x: scroll;
    }
    .product-inventory-add-entries-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8rem;
        align-items: center;
        border-top: 2px solid gray;
        padding-top: 30px;

        .product-inventory-add-entries-inputs {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .product-inventory-input-quantity {
                width: 70px;
                margin: 10px ;
            }

            .product-inventory-input {
                margin: 10px ;
            }
        }

        .product-inventory-input-justification {
            margin: 10px;
            width: 100%;
        }
    }
}