.admin-categories-main-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
    }

    .admin-categories-action-buttons {
        padding: 20px;
        border-bottom: 2px solid gray;

        .admin-add-category-button {
            margin: 10px;
            box-shadow: 2px 2px 5px gray;
        }

        .admin-add-category-dropdown {
            .admin-add-category-button {
                margin-left: 20px;
            }
        }

        .admin-add-sub-category-dropdown {

            .admin-add-sub-category-input{
                margin-left: 10px;
            }

            .admin-add-sub-category-button {
                margin-left: 20px;
            }

            .admin-add-sub-category-select {
                text-transform: capitalize;
                padding: 3px;
            }
        }
    }

    .admin-categories-view-port {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 700px) {
    .admin-categories-main-container {
            display: flex;
            height: 100%;
            flex-direction: column;
            overflow-y: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    display: none;
            }

            h1 {
                font-size: 2rem;
            }

            .admin-categories-action-buttons {
                padding: 20px;
                justify-self: center;
                border-bottom: 2px solid gray;

                .admin-add-category-button {
                    margin: 10px;
                    box-shadow: 2px 2px 5px gray;
                }

                .admin-add-category-dropdown {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .admin-add-category-button {
                        margin-left: 20px;
                    }
                }

                .admin-add-sub-category-dropdown {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .admin-add-sub-category-input{
                        // margin-left: 10px;
                        margin-bottom: 10px;
                    }

                    .admin-add-sub-category-button {
                        margin-left: 10px;
                    }

                    .admin-add-sub-category-select {
                        text-transform: capitalize;
                        margin-left: 10px;
                        margin-bottom: 10px;
                        padding: 3px;
                    }
                }
            }

            .admin-categories-view-port {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
}