.login-page-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%2396ff8a' fill-opacity='0.14'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");
    

    .login-page-form-container {
        padding: 20px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.199);
        display: flex;
        flex-direction: column;

        img {
            margin-bottom: 30px;
            width: 100px;
            align-self: center;
        }

        .login-form {
            display: flex;
            flex-direction: column;
            min-width: 25vw;

            .material-button {
                background-color: green;
            } 
        }
    }

    .login-page-bottom-links {
        display: flex;
        flex-direction: row;

        .react-link {
            padding: 10px 40px;
            text-decoration: none;
            color: green;
            font-weight: 300;
        }
    }

    .without-login {
        font-size: 1rem;
        margin-top: 30px;
        color: green;
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}

// Small Laptop
@media only screen and (min-width: 800px) and (max-width: 1800px) {
    .login-page-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        width: 100%;
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%2396ff8a' fill-opacity='0.14'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");


        .login-page-form-container {
            padding: 20px;
            border-radius: 10px;
            background-color: white;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.199);
            display: flex;
            flex-direction: column;

            img {
                margin-bottom: 30px;
                width: 100px;
                align-self: center;
            }

            .login-form {
                display: flex;
                flex-direction: column;
                min-width: 35vw;

                .material-button {
                    background-color: green;
                } 
            }
        }

        .login-page-bottom-links {
            display: flex;
            flex-direction: row;

            .react-link {
                padding: 10px 40px;
                text-decoration: none;
                color: green;
                font-weight: 300;
            }
        }
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .login-page-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        width: 100%;

        .login-page-form-container {
            padding: 20px;
            border-radius: 5px;
            background-color: whitesmoke;
            box-shadow: 1px 1px 5px rgba(128, 128, 128, 0.247);

            img {
                margin-bottom: 20px;
                width: 75px;
            }

            .login-form {
                display: flex;
                flex-direction: column;
                min-width: 80vw;

                .material-button {
                    background-color: green;
                } 
            }
        }

        .login-page-bottom-links {
            display: flex;
            flex-direction: row;

            .react-link {
                padding: 10px 40px;
                text-decoration: none;
                color: green;
                font-size: 0.7rem;
                font-weight: 300;
            }
        }
    }
}