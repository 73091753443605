.recently-added-products-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid gray;

    h1 {
        font-weight: 300;
    }

    .nap-card-view {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card-main-container {
            margin: 20px;
            width: 300px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.274);
            border-radius: 5px;

            .card-image {
                height: 200px;
            }

            .nap-card-content {
                text-transform: capitalize;

                h3 {
                    color: green;
                    font-weight: 400;
                    text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.253);
                    font-size: 1.2rem;
                }

                .category {
                    color: gray;
                    font-size: 0.8rem;
                }

                .card-details-container {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    align-items: center;

                    .quantity {
                        color: green;
                    }

                    .price {
                        font-size: 1.8rem;
                        color: green;
                    }
                }
            }

            &:hover {
                box-shadow: 2px 2px 5px rgba(0, 128, 0, 0.342);
            }

            .add-to-cart-button {
                color: #089259;
            }

            .quantity-buttons {
                display: flex;
                border: 1px solid #089259;
                border-radius: 10px;
                justify-content: space-between;
                color: white;
                background-color: #089259;
                align-items: center;
                padding: 0px 0px;
                font-size: 15px;
                font-weight: 300;
                // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                .value {
                    padding: 1px 8px;
                    border-right: 1px solid white;
                    border-left: 1px solid white;
                }

                .add {
                    padding: 1px 8px;
                    cursor: pointer;
                }

                .remove {
                    padding: 1px 8px;
                    cursor: pointer;
                }
            }
        }
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .recently-added-products-main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 5px;
        border-bottom: 1px solid gray;
        

        h1 {
            font-weight: 400;
            font-size: 1.2rem;
            border-bottom: 1px solid rgba(128, 128, 128, 0.288);
            width: 100%;
            padding: 10px 0px;
        }

        .nap-card-view {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            width: 100vw;
            

            .card-main-container {
                margin: 5px;
                max-width: 190px;
                box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.274);
                border-radius: 5px;

                .card-image {
                    max-height: 125px;
                }

                .nap-card-content {
                    text-transform: capitalize;

                    h3 {
                        font-size: 0.8rem;
                        color: green;
                        font-weight: 600;
                        text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.253);
                    }

                    .category {
                        color: gray;
                        font-size: 0.6rem;
                    }

                    .card-details-container {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        align-items: center;

                        .quantity {
                            color: green;
                            font-size: 0.6rem;
                        }

                        .price {
                            font-size: 0.8rem;
                            color: green;
                        }
                    }
                }

                &:hover {
                    box-shadow: 2px 2px 5px rgba(0, 128, 0, 0.342);
                }

                .add-to-cart-button {
                    color: #089259;
                    font-size: 0.6rem;
                }

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 0.6rem;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 6px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 6px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 6px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}



// Small Laptop
@media only screen and (min-width: 800px) and (max-width: 1800px) {
  .recently-added-products-main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid gray;

        h1 {
            font-weight: 300;
        }

        .nap-card-view {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .card-main-container {
                margin: 20px;
                width: 225px;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.274);
                border-radius: 5px;

                .card-image {
                    height: 150px;
                }

                .nap-card-content {
                    text-transform: capitalize;

                    h3 {
                        color: green;
                        font-weight: 400;
                        text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.253);
                        font-size: 1rem;
                    }

                    .category {
                        color: gray;
                        font-size: 0.6rem;
                    }

                    .card-details-container {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0px;
                        align-items: center;

                        .quantity {
                            color: green;
                        }

                        .price {
                            font-size: 1.5rem;
                            color: green;
                        }
                    }
                }

                &:hover {
                    box-shadow: 2px 2px 5px rgba(0, 128, 0, 0.342);
                }

                .add-to-cart-button {
                    color: #089259;
                    font-size: 10px;
                }

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 10px;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 8px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 8px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 8px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}