.footer-main-container {
    display: flex;
    flex-direction: column;
    color: white;

    .footer-nav {
        display: flex;
        justify-content: space-between;
        padding: 40px;
   

        .footer-brand {
            width: 30%;
        }

        .footer-links {
            display: flex;
            justify-content: space-around;
            width: 30%;

        }

        .address-phone {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 30%;
        }
    }

    .footer-bottom {
        background-color: #343a40;

        text-align: center;
        padding: 10px;
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .footer-main-container {
        display: flex;
        flex-direction: column;

        .footer-nav {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
       

            .footer-brand {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }

            .footer-links {
                display: flex;
                justify-content: space-between;
                width: 80%;
                margin-bottom: 20px;
                font-size: 0.8rem;
            }

            .address-phone {
                display: flex;
                flex-direction: column;
                width: 80%;
                margin-bottom: 20px;
                font-size: 0.8rem;
            }
        }

        .footer-bottom {
            background-color: #343a40;
            
            text-align: center;
            padding: 10px;
            font-size: 0.8rem;
        }
    }
}