.admin-component-view-main-container {
    display: flex;
    // justify-content: space-around;
    min-width: 100vw;
    min-height: 88vh;

    .admin-mobile-menu {
        display: none;
    }

    .admin-view-left-navigation {
        padding: 10px;
        min-width: 15vw;
        border-right: 1px solid rgb(204, 204, 204);
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .admin-right-view-port {
        padding: 10px;
        min-width: 85vw;
    }
}

@media screen and (max-width: 700px) {
    .admin-component-view-main-container {
        display: flex;
        // justify-content: space-around;
        min-width: 100vw;
        min-height: 88vh;

        .admin-mobile-menu {
        display: block;
    }

        .admin-mobile-menu {
            padding-right: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            position: fixed;
            z-index: 20;
            margin-left: 75%;
            margin-top: 10%;
            background-color: black;
            color: white;
            border-radius: 5px;
            box-shadow: 1px 1px 5px gray;
            font-weight: bold;
            cursor: pointer;

        }

        .admin-view-left-navigation{
            display: none;
        }

        .admin-view-left-mobile-navigation{
            // display: none;
            position: fixed;
            z-index: 30;
            padding: 10px;
            background-color: white;
            min-width: 40vw;
            min-height: 100%;
            border-right: 1px solid rgb(204, 204, 204);
            overflow-y: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .admin-right-view-port {
            padding: 10px;
            min-width: 85vw;
        }
    }
}