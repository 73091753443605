.navigation-main-container {

    .comp-to-hide {
        display: none;
    }
    
    .current-store-logo {
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid rgba(128, 128, 0, 0.418);
    }

    .admin-main-navigation {
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        padding: 20px 5px;

        .nav-item {
            font-size: 0.9rem;
            padding: 10px 0px;
            cursor: pointer;
            transition: 0.5s;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.342);
        }

        .nav-item-active {
            font-size: 0.9rem;
            letter-spacing: 2px;
            font-weight: bold;
            padding: 10px 0px;
            color: darkblue;
            cursor: pointer;
            transition: 0.5s;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.342);
        }

        .nav-inner-item {
            font-size: 0.8rem;
            text-transform: uppercase;
            color: black;
            text-decoration: none;
            padding-left: 10px;
            padding-bottom: 5px;
            cursor: pointer;
            border-bottom: 1px solid rgba(128, 128, 0, 0.192);
        }

        .nav-second-level-item {
            font-size: 0.8rem;
            text-transform: uppercase;
            color: gray;
            text-decoration: none;
            padding-left: 30px;
            padding-bottom: 5px;
            cursor: pointer;
            border-bottom: 1px solid rgba(128, 128, 0, 0.192);
        }
    }

}


@media screen and (max-width: 700px) {
    .navigation-main-container {
        
    }
}