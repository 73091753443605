.admin-dashboard-main-container{
    display: flex;
    flex-direction: column;
    height: 100%;

    .admin-brief-box-container {
        background-color: rgba(163, 218, 240, 0.274);
        display: flex;
        flex-direction: row;
        justify-content: center;

        .admin-brief-box {
            background-color: whitesmoke;
            text-align: center;
            padding: 60px;
            border: 2px solid black;
            border-radius: 10px;
            box-shadow: 2px 2px 10px gray;
            margin: 20px;

            h5 {
                color: gray;
                font-size: 2rem;
            }

            .box-count {
                color: darkblue;
                font-size: 1.5rem;
            }
        }
    }

    .admin-all-products-list {
        padding-top: 60px;
        width: 100%;
        overflow-y: scroll;
        text-transform: capitalize;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}


@media screen and (max-width: 700px) {
    .admin-dashboard-main-container{
        display: flex;
        flex-direction: column;
        height: 100%;
        min-width: 90vw;
        overflow: hidden;

        h2 {
            font-size: 1rem;
        }

        .admin-brief-box-container {
            background-color: rgba(163, 218, 240, 0.274);
            flex-direction: column;
            justify-content: center;

            .admin-brief-box {
                background-color: whitesmoke;
                text-align: center;
                padding: 50px;
                border: 2px solid black;
                border-radius: 10px;
                box-shadow: 2px 2px 10px gray;
                margin: 20px;

                h5 {
                    color: gray;
                    font-size: 1rem;
                }

                .box-count {
                    color: darkblue;
                    font-size: 1.5rem;
                }
            }
        }

        .admin-all-products-list {
            padding-top: 60px;
            width: 100%;
            padding: 10px;
            overflow-y: scroll;
            text-transform: capitalize;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            th {
                font-size: 0.6rem;
            }

            tr {
                font-size: 0.6rem;
            }
        }
    }
}