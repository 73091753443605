.cat-store-pay-status-container {
    min-height: 89vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: center;

    .successful-transaction {
        min-width: 50vw;
        min-height: 50vh;
        border: 1px solid rgb(50, 110, 0);
        border-radius: 20px;
        box-shadow: 2px 2px 10px rgb(0, 59, 8);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #f7f7f7;
    }

    .failed-transaction {
        min-width: 50vw;
        min-height: 50vh;
        border: 1px solid rgb(110, 0, 0);
        border-radius: 20px;
        box-shadow: 2px 2px 10px rgb(59, 0, 0);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f1;
    }
}