.admin-footer-main-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100vw;
    min-height: 6vh;
    background-color: rgb(54, 54, 54);
    box-shadow: -2px -2px 5px gray;

    .admin-footer-text-wrapper {
        color:darkgray;
        padding-right: 30px;
        font-size: 12px;
    }
}

@media screen and (max-width: 700px) {
    .admin-footer-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    min-height: 6vh;
    background-color: rgb(54, 54, 54);
    box-shadow: -2px -2px 5px gray;

    .admin-footer-text-wrapper {
        color:darkgray;
        padding-right: 0px;
        font-size: 12px;
    }
}
}
