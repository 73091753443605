.cat-store-my-account-container {
    min-height: 89vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .my-account-header {
        display: flex;
        width: 100%;
        justify-content: center;
        height: 200px;
        align-items: center;
        background-color: #fbfbfb;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23d8ffd1' fill-opacity='0.67'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");

        h2 {
            font-size: 3rem;
            font-weight: 300;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.473);
        }
    }

    .my-account-options-wrapper {
        display: flex;
        flex-direction: column;
        width: 80%;
        // border: 1px solid black;
        padding: 30px;

        .tab-view {
            a {
                color: green;
                font-size: 1.2rem;
                font-weight: 300;
            }
        }

        .my-orders-tab {
            .my-orders-pane {
                padding: 20px;

                .my-orders-table {
                    font-size: 0.8rem;
                }
            }
        }

        // .my-address-tab {
        //     .my-address-pane {
        //         padding: 20px;

        //         .address-block {
        //             border: 1px solid black;
        //             font-size: 1.5rem;
        //             font-weight: 300;
        //         }
        //     }
        // }
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .cat-store-my-account-container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        .my-account-header {
            display: flex;
            width: 100%;
            justify-content: center;
            height: 100px;
            align-items: center;
            background-color: #fbfbfb;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23d8ffd1' fill-opacity='0.67'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");

            h2 {
                font-size: 2.5rem;
                font-weight: 300;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.473);
            }
        }

        .my-account-options-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            // border: 1px solid black;
            padding: 30px;

            .tab-view {
                a {
                    color: green;
                    font-size: 0.8rem;
                    font-weight: 300;
                }
            }

            .my-orders-tab {
                .my-orders-pane {
                    padding: 5px;

                    .my-orders-table {
                        font-size: 0.5rem;
                    }
                }
            }

            // .my-address-tab {
            //     .my-address-pane {
            //         padding: 20px;

            //         .address-block {
            //             border: 1px solid black;
            //             font-size: 1.5rem;
            //             font-weight: 300;
            //         }
            //     }
            // }
        }
    }
}