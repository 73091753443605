.privacy-policy-main-container {
    padding: 100px;
    text-align: justify;

    ol {
        padding: 0px 100px;
    }
}

/* Media Query: Large Smartphone */

@media screen and (max-width: 600px) {
    .privacy-policy-main-container {
        padding: 30px;
        text-align: justify;

        ol {
            padding: 0px 30px;
        }
    }
}
