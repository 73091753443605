.store-items-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 83vh;

    .store-view-header {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/cat-store-test.appspot.com/o/image-src-cat-store-main%2Fcoorg-basket-product-images%2Fheader-image-store8877623.jpg?alt=media&token=685f82c3-eb7a-4434-97a0-51f60904fbdd");
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;

        .store-heading {
            font-size: 5rem;
            color: white;
            text-shadow: 2px 2px 5px rgb(0, 54, 0);
            font-weight: 300;
            text-transform: capitalize;
        }

        .store-details {
            color: white;
            font-weight: 300;
            text-shadow: 1px 1px 2px black;
            background-color: black;
            box-shadow: 1px 1px 2px green;
            padding: 5px 10px;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;

            span {
                margin: 5px 10px;
            }
        }
    }

    .card-view-store-items {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        // justify-content: center;

        .card-main-container {
            margin: 20px;
            width: 300px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.274);
            border-radius: 5px;

            .card-image {
                height: 200px;
            }

            .card-content {
                text-transform: capitalize;

                h3 {
                    color: green;
                    font-weight: 400;
                    text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.253);
                    font-size: 1.2rem;
                }

                .category {
                    color: gray;
                    font-size: 0.8rem;
                }

                .card-details-container {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    align-items: center;

                    .quantity {
                        color: green;
                    }

                    .price {
                        font-size: 1.8rem;
                        color: green;
                    }
                }
            }

            &:hover {
                box-shadow: 2px 2px 5px rgba(0, 128, 0, 0.342);
            }

            .add-to-cart-button {
                color: #089259;
            }

            .quantity-buttons {
                display: flex;
                border: 1px solid #089259;
                border-radius: 10px;
                justify-content: space-between;
                color: white;
                background-color: #089259;
                align-items: center;
                padding: 0px 0px;
                font-size: 15px;
                font-weight: 300;
                // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                .value {
                    padding: 1px 8px;
                    border-right: 1px solid white;
                    border-left: 1px solid white;
                }

                .add {
                    padding: 1px 8px;
                    cursor: pointer;
                }

                .remove {
                    padding: 1px 8px;
                    cursor: pointer;
                }
            }
        }
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .store-items-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        min-height: 83vh;

        .store-view-header {
            width: 100%;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image: url("https://firebasestorage.googleapis.com/v0/b/cat-store-test.appspot.com/o/image-src-cat-store-main%2Fcoorg-basket-product-images%2Fheader-image-store8877623.jpg?alt=media&token=685f82c3-eb7a-4434-97a0-51f60904fbdd");
            background-position: center;
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-size: cover;

            .store-heading {
                font-size: 2rem;
                color: white;
                text-shadow: 2px 2px 5px rgb(0, 54, 0);
                font-weight: 300;
                text-transform: capitalize;
            }

            .store-details {
                font-size: 0.8rem;
                color: white;
                font-weight: 300;
                text-shadow: 1px 1px 2px black;
                background-color: black;
                box-shadow: 1px 1px 2px green;
                padding: 5px 5px;
                border-radius: 20px;
                display: flex;
                justify-content: space-between;

                span {
                    margin: 2px 4px;
                }
            }
        }

        .card-view-store-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            margin-top: 20px;

            .card-main-container {
                margin: 5px;
                max-width: 190px;
                box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.274);
                border-radius: 5px;

                .card-image {
                    height: 125px;
                }

                .card-content {
                    text-transform: capitalize;

                    h3 {
                        font-size: 0.8rem;
                        color: green;
                        font-weight: 600;
                        text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.253);
                    }

                    .category {
                        color: gray;
                        font-size: 0.6rem;
                    }

                    .card-details-container {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        align-items: center;

                        .quantity {
                            color: green;
                            font-size: 0.6rem;
                        }

                        .price {
                            font-size: 0.8rem;
                            color: green;
                        }
                    }
                }

                &:hover {
                    box-shadow: 2px 2px 5px rgba(0, 128, 0, 0.342);
                }

                .add-to-cart-button {
                    color: #089259;
                }

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 15px;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 8px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 8px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 8px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


// Small Laptop
@media only screen and (min-width: 800px) and (max-width: 1800px) {
    .store-items-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        min-height: 83vh;

        .store-view-header {
            width: 100%;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image: url("https://firebasestorage.googleapis.com/v0/b/cat-store-test.appspot.com/o/image-src-cat-store-main%2Fcoorg-basket-product-images%2Fheader-image-store8877623.jpg?alt=media&token=685f82c3-eb7a-4434-97a0-51f60904fbdd");
            background-position: center;
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-size: cover;

            .store-heading {
                font-size: 5rem;
                color: white;
                text-shadow: 2px 2px 5px rgb(0, 54, 0);
                font-weight: 300;
                text-transform: capitalize;
            }

            .store-details {
                color: white;
                font-weight: 300;
                text-shadow: 1px 1px 2px black;
                background-color: black;
                box-shadow: 1px 1px 2px green;
                padding: 5px 10px;
                border-radius: 20px;
                display: flex;
                justify-content: space-between;

                span {
                    margin: 5px 10px;
                }
            }
        }

        .card-view-store-items {
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            // justify-content: center;

            .card-main-container {
                margin: 20px;
                width: 225px;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.274);
                border-radius: 5px;

                .card-image {
                    height: 150px;
                }

                .card-content {
                    text-transform: capitalize;

                    h3 {
                        color: green;
                        font-weight: 400;
                        text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.253);
                        font-size: 1rem;
                    }

                    .category {
                        color: gray;
                        font-size: 0.8rem;
                    }

                    .card-details-container {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        align-items: center;

                        .quantity {
                            color: green;
                        }

                        .price {
                            font-size: 1.8rem;
                            color: green;
                        }
                    }
                }

                &:hover {
                    box-shadow: 2px 2px 5px rgba(0, 128, 0, 0.342);
                }

                .add-to-cart-button {
                    color: #089259;
                    font-size: 10px;
                }

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 10px;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 8px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 8px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 8px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}