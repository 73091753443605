.admin-orders-main-container {
    display: flex;
    flex-direction: column;

    h5 {
        padding-top: 10px;
        border-top: 2px solid rgba(128, 128, 128, 0.445);
    }

    .admin-orders-status-tabs {
        max-width: 99%;
        // padding: 10px;
        font-size: 0.7rem;
        font-weight: bold;

        .tabbed-table {
            min-height: 50%;
        }
    }
    
    .admin-orders-counters {
        display: flex;
        justify-content: center;
        padding: 20px;
        background-color: whitesmoke;

        .admin-count-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px;
            border: 2px solid black;
            margin: 5px;
        }
    }
}