.admin-header-main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100vw;
    min-height: 10vh;
    // background-color: antiquewhite;
    box-shadow: 2px 2px  4px gray;
    z-index: 10;

    .admin-logo-container {
            padding-left: 0px;

            img {
                padding-left: 30px;
                max-height: 70px;
            }
    }

    .admin-logout-button-container {
        padding: 20px;

        .admin-logout-button {
            box-shadow: 2px 2px 5px gray;
        }
    }    
}


/* Media Query: Large Smartphone */

@media screen and (max-width: 700px) {
    .admin-header-main-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 100vw;
            height: 50px;
            // background-color: antiquewhite;
            box-shadow: 2px 2px  4px gray;
            z-index: 10;

            .admin-logo-container {
                    padding-left: 0px;

                    img {
                        padding-left: 30px;
                        height: 40px;
                    }
            }

            .admin-logout-button-container {
                padding: 20px;

                .admin-logout-button {
                    box-shadow: 2px 2px 5px gray;
                }
            }    
        }
}