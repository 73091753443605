.navbar-main-container {
    height: 75px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.219);
   
}

.basic-navbar-nav {
    .nav-item {
        font-size: 1.1rem;
        margin-right: 20px;
    }

    .dropdown-items {
        text-transform: capitalize;
    }
}

.cart-modal {
    .cart-modal-body {
        padding: 20px;
        max-height: 600px;
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .column-item {
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            text-transform: capitalize;
            text-align: center;
            vertical-align: middle;
            font-weight: 300;

            &.total {
                font-size: 2rem;
                color: green;
            }

            .quantity-buttons {
                display: flex;
                border: 1px solid #089259;
                border-radius: 10px;
                justify-content: space-between;
                color: white;
                background-color: #089259;
                align-items: center;
                padding: 0px 0px;
                font-size: 14px;
                font-weight: 300;
                // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                .value {
                    padding: 1px 8px;
                    border-right: 1px solid white;
                    border-left: 1px solid white;
                }

                .add {
                    padding: 1px 8px;
                    cursor: pointer;
                }

                .remove {
                    padding: 1px 8px;
                    cursor: pointer;
                }
            }
        }
    }
}

// medium phone
@media only screen and (max-width: 600px) {
  .navbar-main-container {
        height: auto;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.219);
       
    }

    .basic-navbar-nav {
        margin-left: 0px;
        text-align: center;
   

        .nav-item {
            font-size: 1.1rem;
            margin-right: 20px;
        
            &.dropdown {
                width: 100%;
                justify-self: center;
            }
        }

        .dropdown-items {
            text-transform: capitalize;
            text-align: center;
        }

        .nav-right-items {
            margin-top: 30px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

    .cart-modal {
        .cart-modal-body {
            padding: 10px;

            .column-item {
                align-items: center;
                justify-content: center;
                font-size: 0.6rem;
                text-transform: capitalize;
                text-align: center;
                vertical-align: middle;

                &.total {
                    font-size: 0.7rem;
                    color: green;
                }

                img {
                    height: 18px;
                }

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 8px;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 4px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 4px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 4px;
                        cursor: pointer;
                    }
                }
            }
        }

        Button {
            font-size: 0.6rem;
        }
    }
}