.signup-page-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;


    .signup-page-form-container {
        padding: 20px;
        border: 1px solid rgb(194, 194, 194);
        border-radius: 5px;
        background-color: whitesmoke;
        box-shadow: 1px 1px 5px gray;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-bottom: 20px;
            width: 75px;
        }

        .login-form {
            display: flex;
            flex-direction: column;
            min-width: 25vw;
        }
    }

    .signup-page-bottom-links {
        display: flex;
        flex-direction: row;

        .react-link {
            padding: 10px 40px;
            text-decoration: none;
            color: green;
            font-weight: 300;
        }
    }
}

// Small Laptop
@media only screen and (min-width: 800px) and (max-width: 1800px) {
    .signup-page-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;


        .signup-page-form-container {
            padding: 20px;
            border: 1px solid rgb(194, 194, 194);
            border-radius: 5px;
            background-color: whitesmoke;
            box-shadow: 1px 1px 5px gray;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 20px;
                width: 75px;
            }

            .login-form {
                display: flex;
                flex-direction: column;
                min-width: 35vw;
            }
        }

        .signup-page-bottom-links {
            display: flex;
            flex-direction: row;

            .react-link {
                padding: 10px 40px;
                text-decoration: none;
                color: green;
                font-weight: 300;
            }
        }
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .signup-page-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;


        .signup-page-form-container {
            padding: 20px;
            border: 1px solid rgb(194, 194, 194);
            border-radius: 5px;
            background-color: whitesmoke;
            box-shadow: 1px 1px 5px gray;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 20px;
                width: 75px;
            }

            .login-form {
                display: flex;
                flex-direction: column;
                min-width: 80vw;
            }
        }

        .signup-page-bottom-links {
            display: flex;
            flex-direction: row;

            .react-link {
                padding: 10px 40px;
                text-decoration: none;
                color: green;
                font-weight: 300;
                font-size: 0.8rem;
            }
        }
    }
}