.admin-products-main-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

    .admin-products-crud-buttons {
        padding: 30px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.137);

        .crud-button {
            box-shadow: 2px 2px 5px gray;
        }
    }

    .admin-products-search-container {
        padding: 20px;

        .admin-products-search {
            border: none;
            padding: 5px 200px 5px 15px;
            box-shadow: inset 2px 2px 5px gray;
            background-color: rgba(224, 255, 255, 0.575);
            border-radius: 10px;

                &::-webkit-search-cancel-button {
                margin-right: -95%;
            }
        }
    }

    .all-products-view-port {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 700px) {
    .admin-products-main-container {
        display: flex;
        height: 100%;
        min-width: 90vw;
        flex-direction: column;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

        .admin-products-crud-buttons {
            display: flex;
            justify-content: center;
            padding: 30px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.137);

            .crud-button {
                box-shadow: 2px 2px 5px gray;
            }
        }

        .admin-products-search-container {
            display: flex;
            justify-content: center;
            padding: 20px;

            .admin-products-search {
                border: none;
                width: 250px;
                padding: 5px 200px 5px 15px;
                box-shadow: inset 2px 2px 5px gray;
                background-color: rgba(224, 255, 255, 0.575);
                border-radius: 10px;

                    &::-webkit-search-cancel-button {
                    margin-right: -450%;
                }
            }
        }

        .all-products-view-port {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}