.banner-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #0f5a3b;

    img {
        width: 60%;
    }

    .banner-head{
 height: 40vh;
 h1{
    font-size: 4rem;
 }
    }
}


// Medium Phone
@media only screen and (max-width: 800px) {
  .banner-main-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0f5a3b;

        img {
            min-width: 100%;
        }
    }
}

// Small Laptop
@media only screen and (min-width: 800px) and (max-width: 1800px) {
  .banner-main-container {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #0f5a3b;

        img {
            width: 70%;
        }
    }
}