.admin-edit-products-modal-form {
    display: flex;
    flex-direction: column;

    .admin-edit-image-wrapper {
        padding: 10px;
        border-bottom: 1px solid gray;

        .admin-upload-image-button {
            margin-top: 10px;
            box-shadow: 1px 1px 5px gray;
        }
    }

    .admin-edit-product-name-wrapper {
        padding: 10px;
    }

    .admin-edit-product-price-quantity-wrapper{
        display: flex;
        padding: 10px;
        
        div {
            width: 40%;
            padding-right: 10px;
        }
    }

    .admin-edit-product-label {
        font-size: 0.8rem;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .form-category-admin-wrapper {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid gray;

        .form-category-block {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-right: 20px;

            .admin-edit-product-category-select-field {
                text-transform: capitalize;
            }

            .admin-edit-product-sub-category-select-field {
                text-transform: capitalize;
            }
        }
    }

    .admin-text-area-group {
        width: 100%;
        display: flex;
        padding: 20px;

        div {
            padding-right: 20px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 700px) {
    .admin-edit-products-modal-form {
        display: flex;
        flex-direction: column;

        .admin-edit-image-wrapper {
            padding: 10px;
            border-bottom: 1px solid gray;

            .admin-upload-image-button {
                margin-top: 10px;
                width: 100%;
                box-shadow: 1px 1px 5px gray;
            }
        }

        .admin-edit-product-name-wrapper {
            padding: 10px;
        }

        .admin-edit-product-price-quantity-wrapper{
            display: flex;
            flex-direction: column;
            padding: 10px;
            
            div {
                width: 100%;
                padding-right: 10px;
            }
        }

        .admin-edit-product-label {
            font-size: 0.8rem;
            font-weight: bold;
            letter-spacing: 1px;
        }

        .form-category-admin-wrapper {
            display: flex;
            flex-direction: column;
            padding: 10px;
            border-bottom: 1px solid gray;

            .form-category-block {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-right: 20px;

                .admin-edit-product-category-select-field {
                    text-transform: capitalize;
                }

                .admin-edit-product-sub-category-select-field {
                    text-transform: capitalize;
                }
            }
        }

        .admin-text-area-group {
            display: flex;
            flex-direction: column;
            padding: 20px;

            div {
                padding-right: 20px;
                width: 100%;
            }
        }
    }
}