.product-details-container {
    min-height: 81vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;

    .product-details-wrapper {
        padding: 20px;
        border-radius: 10px;
        box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.301);
        width: 100%;
        display: flex;

        .left-container {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 65%;
            }
        }

        .right-container {
            display: flex;
            flex-direction: column;
            width: 50%;

            h1 {
                font-weight: 600;
                text-transform: capitalize !important;
            }

            h5 {
                font-weight: 300;
                color: gray;
            }

            p {
                margin-top: 20px;
                font-weight: 300;
                width: 80%;
            }

            .price-quantity {
                font-weight: 300;
                display: flex;
                justify-content: space-between;
                width: 75%;
                margin-top: 20px;

                .quantity {
                    font-size: 1.7rem;
                    color: green;
                }

                .price {
                    font-size: 3rem;
                    color: green;
                }
            }

            .select-button {
                font-weight: 300;
                display: flex;
                justify-content: space-between;
                width: 80%;
                margin-top: 80px;
                align-items: center;

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 15px;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 8px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 8px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 8px;
                        cursor: pointer;
                    }
                }

                .add-to-cart-button {
                    color: #089259;
                }
            }
        }
    }
}

// medium phone
@media only screen and (max-width: 800px) {
    .product-details-container {
        min-height: 81vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: center;
        align-items: center;
    

        .product-details-wrapper {
            padding: 20px;
            border-radius: 10px;
            box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.301);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .left-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 65%;
                }
            }

            .right-container {
                display: flex;
                flex-direction: column;
                width: 100%;
         

                h1 {
                    font-weight: 300;
                    font-size: 2rem;
                }

                h5 {
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: gray;
                }

                p {
                    font-size: 0.8rem;
                    margin-top: 20px;
                    font-weight: 500;
                    width: 100%;
                }

                .price-quantity {
                    font-weight: 300;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 20px;

                    .quantity {
                        font-size: 1.2rem;
                        color: green;
                    }

                    .price {
                        font-size: 2rem;
                        color: green;
                    }
                }

                .select-button {
                    font-weight: 300;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    margin-top: 20px;
                    align-items: center;

                    .quantity-buttons {
                        display: flex;
                        border: 1px solid #089259;
                        border-radius: 10px;
                        justify-content: space-between;
                        color: white;
                        background-color: #089259;
                        align-items: center;
                        padding: 0px 0px;
                        font-size: 15px;
                        font-weight: 300;
                        // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                        .value {
                            padding: 1px 8px;
                            border-right: 1px solid white;
                            border-left: 1px solid white;
                        }

                        .add {
                            padding: 1px 8px;
                            cursor: pointer;
                        }

                        .remove {
                            padding: 1px 8px;
                            cursor: pointer;
                        }
                    }

                    .add-to-cart-button {
                        color: #089259;
                    }
                }
            }
        }
    }
}