.checkout-main-container {
    // width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 83vh;

    .details-form-container {
        width: 50vw;
        padding-bottom: 30px;

        .checkout-form-container {
            padding-right: 50px;

            .inner-container {

                .name-fields {
                    display: flex;
                    border-bottom: 2px solid rgb(202, 202, 202);
                    padding-bottom: 20px;
                    
                    #fname {
                        margin-right: 20px;
                    }
                }

                .address-line-1-2 {
                    padding-top: 20px;
                }

                .state-zip {
                    display: flex;
                    padding-bottom: 20px;
                    border-bottom: 2px solid rgb(202, 202, 202);

                    #state {
                        margin-right: 20px;
                    }
                }

                .contact-details {
                    display: flex;
                    padding-top: 20px;

                    #phone {
                        margin-right: 20px;
                    }
                }
            }
        }

        .place-order-button {
            padding: 10px 60px;
            box-shadow: 1px 1px 5px black;
            
            width:75% !important;
        }
    }
    
    .check-out-items-container {
        padding: 60px;
        display: flex;
        flex-direction: column;
        width: 50vw;

        .column-item {
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            text-transform: capitalize;
            text-align: center;
            vertical-align: middle;
            font-weight: 300;

            .quantity-buttons {
                display: flex;
                border: 1px solid #089259;
                border-radius: 10px;
                justify-content: space-between;
                color: white;
                background-color: #089259;
                align-items: center;
                padding: 0px 0px;
                font-size: 10px;
                font-weight: 300;
                // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                .value {
                    padding: 1px 6px;
                    border-right: 1px solid white;
                    border-left: 1px solid white;
                }

                .add {
                    padding: 1px 6px;
                    cursor: pointer;
                }

                .remove {
                    padding: 1px 8px;
                    cursor: pointer;
                }
            }
        }

        .back-to-store {
            box-shadow: 1px 1px 5px black;
        }

        h2 {
            padding-top: 20px;
            align-self: flex-end;
        }

        .checkout-empty-message {
            color: gray;
            font-size: 30px;
            padding-bottom: 20px;
            border-bottom: 2px solid rgb(214, 214, 214);
        }

        .policy-shipping-information {
            padding-top: 20px;

            h6 {
                text-decoration: underline;
            }

            p {
                color: rgb(163, 161, 161);
            }
        }
    }
}



/* Media Query: Large Smartphone */

@media screen and (max-width: 800px) {
    .checkout-main-container {
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .details-form-container {
            width: 100vw;

            .checkout-form-container {
                text-align: center;
                padding-right: 0;
                min-width: 100vw;
                // margin: 0;
                padding: 5px;

                .inner-container {
                    width: 100%;
                    .name-fields {
                        display: flex;
                        flex-direction: column;
                        border-bottom: 2px solid rgb(202, 202, 202);
                        padding-bottom: 20px;
                        
                        #fname {
                            margin-right: 20px;
                        }
                    }

                    .address-line-1-2 {
                        padding-top: 20px;
                    }

                    .state-zip {
                        display: flex;
                        padding-bottom: 20px;
                        border-bottom: 2px solid rgb(202, 202, 202);

                        #state {
                            margin-right: 20px;
                        }
                    }

                    .contact-details {
                        display: flex;
                        flex-direction: column;
                        padding-top: 20px;

                        #phone {
                            margin-right: 20px;
                        }
                    }
                }
            }

            .place-order-button {
                padding: 10px 60px;
                    box-shadow: 1px 1px 5px black;
                
                    width: 75% !important;
            }
        }

        .check-out-items-container {
            width: 100vw;
            padding: 20px;

            .column-item {
                align-items: center;
                justify-content: center;
                font-size: 0.6rem;
                text-transform: capitalize;
                text-align: center;
                vertical-align: middle;
                font-weight: 500;

                img {
                    display: none;
                }

                .quantity-buttons {
                    display: flex;
                    border: 1px solid #089259;
                    border-radius: 10px;
                    justify-content: space-between;
                    color: white;
                    background-color: #089259;
                    align-items: center;
                    padding: 0px 0px;
                    font-size: 8px;
                    font-weight: 300;
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.301);

                    .value {
                        padding: 1px 6px;
                        border-right: 1px solid white;
                        border-left: 1px solid white;
                    }

                    .add {
                        padding: 1px 6px;
                        cursor: pointer;
                    }

                    .remove {
                        padding: 1px 8px;
                        cursor: pointer;
                    }
                }
            }
        }

        .policy-shipping-information {
            display: block;

            h6 {
                font-size: 0.7rem;
            }

            p {
                font-size: 0.7rem;
            }
        }
    }
}
