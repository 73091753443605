.admin-profile-main-container {
    display: flex;
    flex-direction: column;
    width: 700px;

    .fa-icon-admin-profile {
        font-size: 8rem;
        color: gray;
    }

    .admin-profile-manage-profile {
        display: flex;
        flex-direction: column;
        padding-top: 50px;

        .admin-inner-edit-container {
            display: flex;
            align-items: center;

            .admin-inner-input {
                width: 200px;
                margin-left: 5px;
            }

            .admin-inner-button {
                width: 100px;
                margin-left: 10px;
            }
        }
        

        .admin-profile-detail {
            letter-spacing: 1px;
            padding-bottom: 5px;

            .fa-inner-edit {
                color: green;
                font-size: 0.9rem;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 700px) {
        .admin-profile-main-container {
        display: flex;
        flex-direction: column;
        width: 95vw;
        overflow-wrap: normal;

        .fa-icon-admin-profile {
            font-size: 8rem;
            color: gray;
        }

        .admin-profile-manage-profile {
            display: flex;
            flex-direction: column;
            padding-top: 20px;

            .admin-inner-edit-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                .admin-inner-input {
                    margin-top: 5px;
                    width: 90%;
                }

                .admin-inner-button {
                    width: 100px;
                    margin-top: 10px;
                }
            }
            

            .admin-profile-detail {
                letter-spacing: 1px;
                padding-bottom: 5px;

                .fa-inner-edit {
                    color: green;
                    font-size: 0.9rem;
                    cursor: pointer;
                }
            }
        }
    }
}